import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'
import { asDict, http, useAsyncError } from '@climate/components'

const ENDPOINT = '/api/v1/credit_ratings'

const creditRatingsAtom = atom({
  key: 'creditRatingsState',
  default: [],
})

const creditRatingsAgenciesAtom = atom({
  key: 'creditRatingsAgenciesState',
  default: [],
})

const creditRatingsAgenciesGroupedSelector = selector({
  key: 'creditRatingsAgenciesGrouped',
  get: ({ get }) => {
    const ungrouped = get(creditRatingsAgenciesAtom)
    return ungrouped.reduce((result, item) => {
      if (result[item.agency]) {
        result[item.agency].push({ value: item.id, label: item.rating })
      } else {
        result[item.agency] = [{ value: item.id, label: item.rating }]
      }
      return result
    }, {})
  }
})


export const useCreditRatingsService = (token) => {
  const [creditRatings, setCreditRatingsState] = useRecoilState(creditRatingsAtom)

  const [creditRatingsAgencies, setCreditRatingsAgencies] = useRecoilState(creditRatingsAgenciesAtom)

  const throwError = useAsyncError()

  const loadCreditRatings = async () => {
    try {
      const data = (await http.get({ url: ENDPOINT, token }))
      setCreditRatingsState(asDict(data, (e) => (`(${e.value}) ${e.name}`)))
      return data
    } catch (e) {
      throwError(e)
    }
  }

  const loadCreditRatingsAgencies = async () => {
    try {
      const data = (await http.get({ url: `${ENDPOINT}/agencies`, token }))
      setCreditRatingsAgencies(data)
      return data
    } catch (e) {
      throwError(e)
    }
  }

  return {
    creditRatings,
    loadCreditRatings,
    loadCreditRatingsAgencies,
    creditRatingsAgencies,
    creditRatingsAgenciesGrouped: useRecoilValue(creditRatingsAgenciesGroupedSelector)
  }
}
