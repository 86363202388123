import React from 'react'

function Spinner({ size = 16, my = 4 }) {
  return (
    <div className={`flex justify-center items-center my-${my}`}>
      <div className={`animate-spin rounded-full h-${size} w-${size} border-b-2 border-emerald`}/>
    </div>
  )
}

export default Spinner
