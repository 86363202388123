import Cookies from 'universal-cookie'

import { atom, useRecoilState } from 'recoil'
import { destroy, get, post } from './Http'
import { capitalize } from '../helpers'

const token = (sessionCookieName) => {
  const cookies = new Cookies()
  return cookies.get(sessionCookieName)
}

const login = async (authServiceUrl, email, password, validationToken, token) => {
  const response = await post({
    url: `${authServiceUrl}/api/v1/users/login`,
    payload: { user: { email, password }, "validation_token": validationToken},
    token
  })
  localStorage.setItem('userData', JSON.stringify(response))
  return response
}

const sync = async (authServiceUrl, token) => {
  const response = await get({ url: `${authServiceUrl}/api/v1/users/sync`, token })
  localStorage.setItem('userData', JSON.stringify(response))
  return response
}

const _logout = async (authServiceUrl) => {
  await destroy({ url: `${authServiceUrl}/api/v1/users/logout` })
}

const register = async (authServiceUrl, payload, token) => {
  return await post({ url: `${authServiceUrl}/api/v1/users`, payload, token })
}

const switchRole = async (authServiceUrl, role, token) => {
  const response = await post({ url: `${authServiceUrl}/api/v1/users/switch_role`, payload: { role }, token })
  localStorage.setItem('userData', JSON.stringify(response))
  return response
}

const getUserData = () => {
  let userDataRaw = localStorage.getItem('userData')
  return userDataRaw == null ? {} : JSON.parse(userDataRaw)
}

const userState = atom({
  key: 'userDataState',
  default: getUserData()
})

const activeRole = (user, scope) => (user.active_roles || []).find((role) => role.startsWith(scope))

const nextRole = (user, scope) => {
  const activeRole = (user.active_roles || []).find((role) => role.startsWith(scope))
  const roles = (user.allowed_roles || []).filter((role) => role.startsWith(scope))
  const rolesRotated = [...roles, ...roles]
  const nextRoleIndex = rolesRotated.indexOf(activeRole) + 1
  return roles.length > 1 ? rolesRotated[nextRoleIndex] : undefined
}

export function useUserService(authServiceUrl, sessionCookieName, scope) {
  const [user, setUser] = useRecoilState(userState)
  const lazyToken = () => token(sessionCookieName)

  return {
    user,
    userState,
    token: () => token(sessionCookieName),
    activeRole: () => activeRole(user, scope),
    activeRoleName: () => {
      const role = activeRole(user, scope)
      return role ? capitalize(role.split(':')[1]) : undefined
    },
    nextRole: () => nextRole(user, scope),
    nextRoleName: () => {
      const role = nextRole(user, scope)
      return role ? capitalize(role.split(':')[1]) : undefined
    },
    switchRole: async (role) => {
      const user = await switchRole(authServiceUrl, role, lazyToken)
      setUser(user)
      return user
    },
    login: async ({ email, password, validationToken }) => {
      const user = await login(authServiceUrl, email, password, validationToken, lazyToken)
      setUser(user)
      return user
    },
    sync: async () => {
      const user = await sync(authServiceUrl, lazyToken)
      setUser(user)
      return user
    },
    resetUser: () => {
      localStorage.removeItem('userData')
      setUser({})
    },
    _logout: async () => { // NOTE: only meant to be used in IAM service
      localStorage.removeItem('userData')
      setUser({})
      await _logout(authServiceUrl, lazyToken)
    },
    isUserInRole: (role) => {
      return !role || (user.active_roles || []).includes(role)
    },
    register: async (payload) => {
      return register(authServiceUrl, payload, lazyToken)
    }
  }
}
