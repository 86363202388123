import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserService } from '@climate/components'

export default function Home() {

  const { user } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')
  const history = useHistory()
  user.entity_status !== 'active' && history.replace('/app/onboard')

  useEffect(() => {
    const app = user.allowed_applications[0] || 'ppa'
    window.location.replace(user.active_applications[app].url)
  }, [user])

  return <></>
}
