import { HttpError } from './HttpError'

const getHeaders = (lazyToken) => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...lazyToken && { 'Authorization': `Token ${lazyToken()}` }
  }
}

const doFetch = async (fetcher) => {
  const response = await fetcher()

  if (!response.ok) {
    throw new HttpError(response.status, response)
  }
  try {
    return await response.json()
  } catch (err) {
    return false
  }
}

export const get = ({ url, token, ...rest }) => doFetch(() => fetch(url, {
  method: 'GET',
  headers: getHeaders(token),
  ...rest
}))

export const post = ({ url, payload, token, ...rest }) => doFetch(() => fetch(url, {
  method: 'POST',
  headers: getHeaders(token),
  body: JSON.stringify(payload),
  ...rest
}))

export const put = ({ url, payload, token, ...rest }) => doFetch(() => fetch(url, {
  method: 'PUT',
  headers: getHeaders(token),
  body: JSON.stringify(payload),
  ...rest
}))

export const destroy = ({ url, token, ...rest }) => doFetch(() => fetch(url, {
  method: 'DELETE',
  headers: getHeaders(token),
  ...rest
}))
