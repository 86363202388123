import { scaleOrdinal } from '@visx/scale'
import { defaultStyles } from '@visx/tooltip'
import { statsColors } from './colors'

// common chart parameters

export const margin = 30

export const commonTickLabelProps = {
  fill: '#333',
  fontSize: 12,
  fontFamily: 'sans-serif',
  textAnchor: 'middle',
  width: 0,
}

export const leftTickLabelProps = () => ({
  ...commonTickLabelProps,
  dx: '-5',
  dy: '-5',
})

export const axisColor = '#aaa'

export const tooltipStyles = {
  ...defaultStyles,
  background: '#fff',
  border: '1px solid #055484',
  color: '#333',
}

export const techColorScale = scaleOrdinal({
  domain: [...Object.keys(statsColors.status), ...Object.keys(statsColors.techs)],
  range: [...Object.values(statsColors.status), ...Object.values(statsColors.techs)],
})
