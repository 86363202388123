import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserService } from '@climate/components'

export default function Logout() {
  const history = useHistory()
  const { _logout } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')

  useEffect(async () => {
    await _logout()
    const urlParams = new URLSearchParams(window.location.search)

    history.replace(`/app/login?redirect_url=${urlParams.get('redirect_url')}`)

  }, [])

  return <></>
}
