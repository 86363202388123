import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Alert, Button, Input, Logo, useUserService } from '@climate/components'
import LogoImage from '../../assets/images/logo.svg'

export default function Login() {
  const history = useHistory()
  const location = useLocation()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const { login } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')
  const [remoteErrors, setRemoteErrors] = useState()

  const loginAction = async (data) => {
    setRemoteErrors(undefined)

    try {
      let validationToken = null
      if (window.config.google_recaptcha_site_key) {
        validationToken = await grecaptcha.execute(window.config.google_recaptcha_site_key, {action: 'login'})
      }

      const { entity_status } = await login({...data, validationToken})
      const redirect = new URLSearchParams(location.search).get('redirect_url')

      if (entity_status !== 'active') { // stay at the onboarding screen if inactive
        history.replace('/app/onboard')
      } else if (redirect) { // go back if another app requested login
        window.location.replace(redirect)
      } else { // otherwise go to the home screen
        history.replace('/app/home')
      }
    } catch (e) {
      const { message, error } = JSON.parse(await e.data.text())
      setRemoteErrors(message?.[0] || error)
    }
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo LogoImage={LogoImage} alt="Tellus Iam logo"/>

        {location.search.includes('confirmed') && (
          <div className="mt-6">
            <Alert type="success" message="Email verified"/>
          </div>
        )}

        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Sign in to your account</h2>

        <div className="mt-8">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form id="sign_in_form" className="space-y-6" onSubmit={handleSubmit(loginAction)}>
              <div>
                <label htmlFor="email" className="pb-2 block text-sm text-gray-700">
                  Email address
                </label>
                <Input
                  id="email"
                  name="email"
                  register={register('email', { required: 'Required', })}
                  errors={errors}
                  autoComplete="email"
                />
              </div>

              <div>
                <label htmlFor="password" className="pb-2 block text-sm text-gray-700">
                  Password
                </label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  register={register('password', { required: 'Required', })}
                  errors={errors}
                  autoComplete="password"
                />
              </div>

              {remoteErrors &&
                <Alert type="danger" message={remoteErrors}/>
              }

              <div className="py-2">
                <Button
                  id="sign_in"
                  className="w-full justify-center"
                  type="submit"
                  size={3}
                >
                  Sign in
                </Button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"/>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    {'Don\'t have an account?'}&nbsp;
                    <Link to="/app/register"
                      className="font-medium text-perfect-shine hover:text-perfect-shine-light">Sign up</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
