import React from 'react'
import classNames from 'classnames'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

const Select = (props) => {
  const {
    name,
    options,
    groupedOptions,
    register,
    errors,
    placeholder,
    className,
    containerClass = 'sm:max-w-xs',
    ...otherProps
  } = props
  const classes = classNames(
    'block w-full shadow-sm pr-10 pl-7 sm:text-sm rounded-md',
    {
      'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': errors?.[name],
      'focus:ring-fine-barrel-light focus:border-blue-500 block w-full border-gray-300': !errors?.[name],
    },
    className,
  )

  return (
    <div className="flex flex-col">
      <div className={`relative ${containerClass}`}>
        <select
          {...otherProps}
          {...register}
          className={classes}
          defaultValue=""
        >
          <option value="" disabled hidden>{placeholder}</option>
          {options && (Array.isArray(options)
              ? options.map((key) => (
                <option key={key} value={key}>{key}</option>
              ))
              : Object.keys(options).map((key) => (
                <option key={key} value={key}>{options[key]}</option>
              ))
          )
          }
          {groupedOptions && (Object.keys(groupedOptions).map((groupLabel, key) => (
              <optgroup label={groupLabel} key={key}>
                {
                  groupedOptions[groupLabel].map(({ label, value }) => (
                    <option className="mb-4 pb-4" key={label} value={value}>{label}</option>
                  ))
                }
              </optgroup>
            ))
          )
          }
        </select>
        {errors?.[name] && (
          <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
          </div>
        )}
      </div>
      {errors && (
        <p className="mt-2 text-sm text-red-600 select-none">
          {errors?.[name]?.message || 'ㅤ'}
        </p>
      )}
    </div>
  )
}

export default Select
