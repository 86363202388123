export const allColors =
  [
    '#3B5DFF',
    '#4FC601',
    '#8CD0FF',
    '#8FB0FF',
    '#0086ED',
    '#7900D7',
    '#B903AA',
    '#BC23FF',
    '#CC0744',
    '#FF2F80',
    '#FF4A46',
    '#FF34FF',
    '#FF913F',
    '#FFB500',
  ]


export const statsColors = {
  status: {
    active: '#0086ED',
    match: '#A30059',
    complete: '#3B9700',
  },
  techs: {
    wind: '#0089A3',
    solar: '#FFB500',
    geothermal: '#FF4A46',
  },
  envCommodityTypes: {
    'rec': '#40916c',
    'srec': '#FFB500',
    'ver': '#0077b6',
  }
}

export default allColors
