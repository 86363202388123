export const assuranceTypes = ['Cash', 'LOC', 'Guaranty']
export const technologyTypes = ['Wind', 'Solar', 'Geo-Thermal']
export const disruptionFallbackTypes = ['Fallback Reference Price', 'Negotiated Fallback', 'No Fault Termination']
export const calculationAgentTypes = ['Buyer', 'Seller']

export const siteStatusTypes = [
  'Site controlled',
  'Interconnect signed',
  'Discret. Permits complete',
  'Env. Studies complete',
  'NTP Const. commenced',
  'ISO Interconnect complete',
  'Commercial operation'
]

export const formsOfOrganization = [
  'C-Corporation',
  'S-Corporation',
  'Corporation (Unspecified)',
  'Limited Liability Company',
  'Limited Partnership',
  'General Partnership',
  'Sole Proprietor',
  'Trust/Estate',
  'Other',
]

export const organizationSizeTypes = [
  'Assets',
  'Annual Revenue'
]

export const utilityCompanyTypes = [
  'No',
  'Yes'
]

export const maxYearsAhead = 5 // maximum amount of years in the future for the project to start

export const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
}

export const usStates = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
}

export const caProvinces = {
  'NL': 'Newfoundland and Labrador',
  'PE': 'Prince Edward Island',
  'NS': 'Nova Scotia',
  'NB': 'New Brunswick',
  'QC': 'Quebec',
  'ON': 'Ontario',
  'MB': 'Manitoba',
  'SK': 'Saskatchewan',
  'AB': 'Alberta',
  'BC': 'British Columbia',
  'YT': 'Yukon',
  'NT': 'Northwest Territories',
  'NU': 'Nunavut',
}

export const countries = {
  'United States': usStates,
  'Canada': caProvinces,
}

export const esFactorEnvironment = [
  'Env - Additionality + Max GHG reduction +2',
  'Env - Additionality + No forest, wetlands destroyed +1',
  'Env - New Project, Additionality +0',
  'Env - Existing project, No Additionality  -1'
]

export const esFactorSite = [
  'Site - Previously developed Site +2',
  'Site - New site, Design min. impacts + Env mitigation +1',
  'Site - New site, Low impact or Partial mitigation +0',
  'Site - New site, Negatively impacts sensitive areas -1'
]

export const esFactorSocial = [
  'Social - Has Community Support + Developer DIE +2',
  'Social - Has Community Support +1',
  'Social - Community Neutral +0',
  'Social - Lacks Community Support -1'
]
