import React from 'react'
import classNames from 'classnames'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

const Input = (props) => {

  const {
    name,
    register,
    type = 'text',
    errors,
    prefix,
    postfix,
    className,
    containerClass = 'sm:max-w-xs',
    step = 'any',
    ...otherProps
  } = props
  const classes = classNames(
    'max-w-lg block w-full shadow-sm pr-10 pl-7 sm:text-sm rounded-md',
    {
      'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500': errors[name],
      'focus:ring-fine-barrel-light focus:border-blue-500 block w-full border-gray-300': !errors[name],
    },
    className,
  )

  return (
    <div className={`flex flex-col ${type === 'hidden' ? 'hidden' : ''}`}>
      <div className={`relative ${containerClass}`}>
        {prefix && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">{prefix}</span>
          </div>
        )}
        <input
          {...otherProps}
          {...register}
          type={type}
          className={classes}
          {...(type === 'number' ? { step } : {})}
        />
        {(postfix || errors[name]) && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none space-x-1">
            {postfix && (
              <span className="text-gray-500 sm:text-sm">
                                {postfix}
                            </span>
            )}
            <div className="h-5 w-5">
              {errors[name] && (
                <ExclamationCircleIcon className="text-red-500" aria-hidden="true"/>
              )}
            </div>
          </div>
        )}
      </div>

      <p className="mt-2 text-sm text-red-600 select-none">
        {errors?.[name]?.message || 'ㅤ'}
      </p>

    </div>
  )
}

export default Input
