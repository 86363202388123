import React from 'react'


import { Toaster } from 'react-hot-toast'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

// import ErrorBoundary from './components/ErrorBoundary'
import { ErrorBoundary, useUserService } from '@climate/components'
import { PrivateRoute } from './components/PrivateRoute'
import StackedLayout from './layouts/Stacked/Layout'

import { Login, Logout, Onboard, Register } from './views'
import AcceptInvitation from './views/AcceptInvitation'

const Routes = () => {
  const { user } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')

  const defaultRoute = user.entity_status !== 'active' ? '/app/onboard' : '/app/home'

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={defaultRoute} />
      </Route>
      <Route path="/app/login" component={Login} />
      <Route path="/app/logout" component={Logout} />
      <Route path="/app/register" component={Register} />
      <Route path="/app/accept_invitation" component={AcceptInvitation} />
      <PrivateRoute path="/app/onboard" component={Onboard} />
      <PrivateRoute path="/app" component={StackedLayout} />
    </Switch>
  )
}

export default function App() {
  return (
    <React.StrictMode>
      <Toaster position="bottom-right" toastOptions={{ duration: 5000 }} />
      <RecoilRoot>
        <BrowserRouter>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </BrowserRouter>
      </RecoilRoot>
    </React.StrictMode>
  )
}
