import { Alert, Button, Input, Logo, Select, useUserService } from '@climate/components'
import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import LogoImage from '../../assets/images/logo.svg'

export const passValidationRules = [
  {
    regEx: /(?=.*[A-Z])/,
    message: 'Must contain an uppercase letter',
  },
  {
    regEx: /(?=.*[a-z])/,
    message: 'Must contain a lowercase letter',
  },
  {
    regEx: /^\S*$/,
    message: 'Cannot contain spaces',
  },
  {
    regEx: /(?=.*[0-9])/,
    message: 'Must contain a number',
  },
  {
    regEx: /(?=.*[-!@#\$%\^&\*])/,
    message: 'Must contain a special character',
  },
  {
    regEx: /^.{8,128}$/,
    message: 'Must be 8-128 characters long',
  },
]


export default function RegisterPage() {

  const [createdUser, setCreatedUser] = useState()

  const RegistrationForm = () => {
    const { register, handleSubmit, watch, formState: { errors, isDirty } } = useForm()
    const { register: registerUser } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')
    const [remoteErrors, setRemoteErrors] = useState()

    const user_password = useRef({})
    user_password.current = watch('user_password', '')

    const registerAction = async (data) => {
      setRemoteErrors(undefined)
      try {
        let validationToken = null
        if (window.config.google_recaptcha_site_key) {
          validationToken = await grecaptcha.execute(window.config.google_recaptcha_site_key, { action: 'register' })
        }

        const user = await registerUser({ ...data, "validation_token": validationToken })
        setCreatedUser(user)
      } catch (e) {
        const { title } = JSON.parse(await e.data.text())
        const [_, message] = title.split('Validation failed:')
        setRemoteErrors(message)
      }
    }

    return (
      <>
        <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Register new account</h2>
        <div className="mt-8">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="grid sm:gap-x-8 sm:gap-y-6 gap-y-4 grid-cols-1 sm:grid-cols-8"
              onSubmit={handleSubmit(registerAction)}>

              {/* region Registration details */}
              <h3 className="sm:col-span-8 text-lg leading-6 font-medium text-gray-900 pb-4 border-b border-gray-300">
                User Details
              </h3>

              <div className="sm:col-span-8">
                <label htmlFor="entity_name" className="pb-2 block text-sm text-gray-700">
                  Organization legal name
                </label>
                <Input
                  id="entity_name"
                  name="entity_name"
                  register={register('entity_name', { required: 'Required', })}
                  errors={errors}
                  autoComplete="organization"
                  containerClass="w-full"
                />
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="user_role" className="pb-2 block text-sm text-gray-700">
                  Buying or selling?
                </label>
                <Select
                  id="user_role"
                  name="user_role"
                  options={{
                    Buyer: 'Buying',
                    Seller: 'Selling',
                  }}
                  register={register('user_role', { required: 'Required' })}
                  errors={errors}
                  placeholder=""
                  className="mr-4"
                />
              </div>

              <div className="sm:col-span-5">
                <label htmlFor="user_email" className="pb-2 block text-sm text-gray-700">
                  Email address
                </label>
                <Input
                  id="user_email"
                  type="email"
                  name="user_email"
                  register={register('user_email', {
                    required: 'Required',
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email',
                    },
                  })}
                  errors={errors}
                  autoComplete="email"
                />
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="user_full_name" className="pb-2 block text-sm text-gray-700">
                  Your full name
                </label>
                <Input
                  id="user_full_name"
                  name="user_full_name"
                  register={register('user_full_name', { required: 'Required', })}
                  errors={errors}
                  autoComplete="name"
                />
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="user_title" className="pb-2 block text-sm text-gray-700">
                  Your title
                </label>
                <Input
                  id="user_title"
                  name="user_title"
                  register={register('user_title', { required: 'Required', })}
                  errors={errors}
                  autoComplete="organization-title"
                />
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="user_password" className="pb-2 block text-sm text-gray-700">
                  Password
                </label>
                <Input
                  id="user_password"
                  type="password"
                  name="user_password"
                  register={register('user_password', {
                    required: 'Required',
                    validate: (value) => passValidationRules.reduce((result, rule) => result || (!rule.regEx.test(value) && rule.message), false) || true
                  })}
                  errors={errors}
                  autoComplete="new-password"
                />
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="user_password_confirmation" className="pb-2 block text-sm text-gray-700">
                  Confirm password
                </label>
                <Input
                  id="user_password_confirmation"
                  type="password"
                  name="user_password_confirmation"
                  register={register('user_password_confirmation', {
                    required: 'Required',
                    validate: (value) => value === user_password.current || 'Passwords do not match'
                  })}
                  errors={errors}
                  autoComplete="new-password"
                />
              </div>
              {/* endregion Registration details */}

              {/* region Products details */}
              <h3 className="sm:col-span-8 text-lg leading-6 font-medium text-gray-900 pb-4 border-b border-gray-300">
                Select Suite Products
              </h3>

              <div className="sm:col-span-8">
                <fieldset className="border-b border-gray-300">
                  <div className="divide-y divide-gray-200">
                    <div className="relative flex items-start py-4">
                      <div className="min-w-0 flex-1 text-sm">
                        <label htmlFor="product_ppa_flag" className="font-medium text-gray-700">Tellus PPA</label>
                        <p className="text-gray-500">Marketplace for clean power</p>
                        <p className="text-gray-500">(No upfront costs)</p>
                      </div>
                      <div className="ml-3 flex h-5 items-center">
                        <input id="product_ppa_flag" aria-describedby="comments-description" name="comments"
                          type="checkbox"
                          defaultChecked={true}  {...register('product_ppa_flag')}
                          className="h-4 w-4 rounded border-gray-300 text-bonfire-dark-light focus:text-bonfire-dark-light" />
                      </div>
                    </div>
                    <div className="relative flex items-start py-4">
                      <div className="min-w-0 flex-1 text-sm">
                        <label htmlFor="product_carbon_flag" className="font-medium text-gray-700">Tellus Carbon</label>
                        <p className="text-gray-500">Marketplace for carbon offsets and RECs</p>
                        <p className="text-gray-500">(No upfront costs)</p>
                      </div>
                      <div className="ml-3 flex h-5 items-center">
                        <input id="product_carbon_flag" aria-describedby="comments-description" name="comments"
                          type="checkbox"
                          defaultChecked={true} {...register('product_carbon_flag')}
                          className="h-4 w-4 rounded border-gray-300 text-bonfire-dark-light focus:text-bonfire-dark-light" />
                      </div>
                    </div>
                    <div className="relative flex items-start py-4">
                      <div className="min-w- b0 flex-1 text-sm">
                        <label htmlFor="product_sustain_flag" className="font-medium text-gray-700">Tellus
                          Sustain</label>
                        <p className="text-gray-500">Sustainability management and carbon accounting</p>
                        <p className="text-gray-500">(Monthly fee)</p>
                      </div>
                      <div className="ml-3 flex h-5 items-center">
                        <input id="product_sustain_flag" aria-describedby="comments-description" name="comments"
                          type="checkbox"
                          defaultChecked={true} {...register('product_sustain_flag')}
                          className="h-4 w-4 rounded border-gray-300 text-bonfire-dark-light focus:text-bonfire-dark-light" />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              {/* endregion Products details */}


              {remoteErrors &&
                <div className="sm:col-span-8">
                  <Alert type="danger" message={remoteErrors} />
                </div>
              }

              <div className="sm:col-span-8 py-2">
                <Button
                  id="submit"
                  type="submit"
                  className="w-full justify-center"
                  size={3}
                  disabled={!isDirty}
                >
                  Register
                </Button>

              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Have an account? <Link to="/app/login"
                      className="font-medium text-perfect-shine hover:text-perfect-shine-light">Sign in</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const EmailConfirmationMessage = () => (
    <>
      <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Confirm your email</h2>
      <div className="mt-8">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-8">
          <p>
            Thank you for registering! An email was sent out to <span
              className="text-perfect-shine-light">{createdUser.email}</span> confirming
            this registration
          </p>
          <p>
            Please click on a link in the email to activate your account and continue onboarding
          </p>
        </div>
      </div>
    </>
  )

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <Logo LogoImage={LogoImage} alt="Tellus Iam logo" />
        {createdUser
          ? <EmailConfirmationMessage />
          : <RegistrationForm />
        }
      </div>
    </div>
  )
}
