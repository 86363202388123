import React from 'react'

export default function Logo({ LogoImage, alt = 'Tellus Logo', classes = 'mx-auto' }) {
  return (
    <img
      className={`${classes} h-12 sm:h-10 md:h-12 w-auto`}
      src={LogoImage}
      alt={alt}
    />
  )
}
