import React from 'react'
import { BarStackHorizontal } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { defaultStyles, Tooltip, withTooltip } from '@visx/tooltip'
import { LegendOrdinal } from '@visx/legend'
import { ParentSize } from '@visx/responsive'

import allColors from './colors'

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 }
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
  lineHeight: '1rem',
}

let tooltipTimeout

const BarStackHorizontalChart = withTooltip(
  ({
    data,
    chartSideLabels,
    xKey,
    width,
    height,
    margin = defaultMargin,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
    colorScaleRange = allColors,
    showLegend,
  }) => {
    // bounds
    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    const keys = Object.keys(data[0]).filter((d) => d !== xKey)

    const valueTotals = data.reduce((allTotals, xValues) => {
      const totalValue = keys.reduce((localTotal, k) => {
        localTotal += Number(xValues[k])
        return localTotal
      }, 0)
      allTotals.push(totalValue)
      return allTotals
    }, [])

    const getX = (d) => d[xKey]

    const yScale = scaleBand({
      domain: data.map(getX),
      padding: 0.2,
    })
    const xScale = scaleLinear({
      domain: [0, Math.max(...valueTotals)],
      nice: true,
    })
    const colorScale = scaleOrdinal({
      domain: keys,
      range: colorScaleRange,
    })

    xScale.rangeRound([0, xMax])
    yScale.rangeRound([yMax, 0])

    return width < 10 ? null : (
      <div>
        <svg width={width} height={height}>
          <Group top={margin.top} left={margin.left}>
            <BarStackHorizontal
              data={data}
              keys={keys}
              height={yMax}
              y={getX}
              xScale={xScale}
              yScale={yScale}
              color={colorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <rect
                      key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                      x={bar.x}
                      y={bar.y}
                      width={bar.width}
                      height={bar.height}
                      fill={bar.color}
                      onMouseLeave={() => {
                        tooltipTimeout = window.setTimeout(() => {
                          hideTooltip()
                        }, 300)
                      }}
                      onMouseMove={() => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout)
                        const top = bar.y + margin.top
                        const left = bar.x + bar.width + margin.left
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: top,
                          tooltipLeft: left,
                        })
                      }}
                    />
                  )),
                )
              }
            </BarStackHorizontal>
          </Group>
        </svg>
        {showLegend && (
          <div
            style={{
              position: 'absolute',
              bottom: '-25px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              fontSize: '12px',
            }}
          >
            <LegendOrdinal scale={colorScale} direction="row" labelMargin="0 15px 0 0"/>
          </div>
        )}
        {chartSideLabels && (
          <>
            <div
              style={{
                position: 'absolute',
                top: '20px',
                left: '20px',
                fontSize: '14px',
              }}
            >
              {Object.keys(chartSideLabels[0])[0]}
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '20px',
                left: '20px',
                fontSize: '14px',
              }}
            >
              {Object.values(chartSideLabels[0])[0]}
            </div>
            <div
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                fontSize: '14px',
              }}
            >
              {Object.keys(chartSideLabels[1])[0]}
            </div>
            <div
              style={{
                position: 'absolute',
                bottom: '20px',
                right: '20px',
                fontSize: '14px',
              }}
            >
              {Object.values(chartSideLabels[1])[0]}
            </div>
          </>
        )}
        {
          tooltipOpen && tooltipData && (
            <Tooltip top={tooltipTop} left={tooltipLeft - 200} style={tooltipStyles}>
              <div className="space-y-2">
                <div style={{ color: colorScale(tooltipData.key) }}>
                  <strong>{tooltipData.key}</strong>
                </div>
                <div>{tooltipData.bar.data[tooltipData.key]} tons of CO₂</div>
              </div>
            </Tooltip>
          )
        }
      </div>
    )
  },
)


const BarStackHorizontalChartContainer = ({
  data,
  margin,
  minHeight = 400,
  xKey,
  chartSideLabels,
  colorScaleRange,
  showLegend = false
}) => (
  <ParentSize style={{ minHeight }}>
    {(parent) =>
      <BarStackHorizontalChart
        margin={margin}
        data={data}
        xKey={xKey}
        width={parent.width}
        height={parent.height}
        chartSideLabels={chartSideLabels}
        colorScaleRange={colorScaleRange}
        showLegend={showLegend}
      />
    }
  </ParentSize>
)

export default BarStackHorizontalChartContainer
