import React from 'react'

import { CheckIcon, ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

export const alertTypes = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
}

const Alert = ({ type = alertTypes.info, message, children, ...otherAttrs }) => {
  const Icon = () => ({
    [alertTypes.info]: <InformationCircleIcon className="h-7 w-7 text-polo-blue-dark" aria-hidden="true"/>,
    [alertTypes.success]: <CheckIcon className="h-7 w-7 text-emerald" aria-hidden="true"/>,
    [alertTypes.warning]: <ExclamationCircleIcon className="h-7 w-7 text-anzac" aria-hidden="true"/>,
    [alertTypes.danger]: <ExclamationIcon className="h-7 w-7 text-cerise-red" aria-hidden="true"/>,
  }[type])

  const color = ({
    [alertTypes.info]: 'botticelli',
    [alertTypes.success]: 'emerald',
    [alertTypes.warning]: 'anzac',
    [alertTypes.danger]: 'cerise-red',
  }[type])

  return (
    <div className={`bg-${color}-light border sm:rounded-md p-4 text-sm`} {...otherAttrs}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon/>
        </div>
        <div className="ml-3">
          {message && (
            <p className={`text-${color}-dark`}>
              {message}
            </p>)
          }
          {children}
        </div>
      </div>
    </div>
  )
}

export default Alert
