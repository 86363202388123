import React, { useEffect, useMemo, useRef } from 'react'
import toast from 'react-hot-toast'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Alert, Button, dollarsToScientific, Input, Logo, Select, useUserService } from '@climate/components'
import { useCreditRatingsService, useEntitiesService } from '../services'
import { useEntitySignatureModal } from '../components/SignatureModals'

import { countries, formsOfOrganization, organizationSizeTypes, utilityCompanyTypes, usStates } from '../consts'

import LogoImage from '../../assets/images/logo.svg'

export default function OnboardPage() {

  const history = useHistory()
  const { register, handleSubmit, getValues, setValue, watch, formState: { errors, isDirty } } = useForm()

  const { user, sync, token } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')
  const { entity, getEntity, updateEntity, getSignatureUrl } = useEntitiesService(token)
  const {
    creditRatingsAgencies,
    creditRatingsAgenciesGrouped,
    loadCreditRatingsAgencies
  } = useCreditRatingsService(token)

  const loadEntity = async () => await getEntity(user.entity_id)

  // todo: refresh page more gracefully
  const { setEntitySignatureModalOpen } = useEntitySignatureModal(() => location.reload())
  user.entity_status === 'active' && history.replace('/')

  useEffect(loadEntity, [user.entity_id])
  useEffect(async () => {
    await sync()
    user.entity_status === 'active' && history.replace('/')
  }, [])

  useEffect(() => {
    if (entity) {
      [
        'agency_credit_rating_id',
        'agency_credit_rating_date',
        'form_of_organization',
        'jurisdiction_of_organization',
        'jurisdiction_of_organization_identifier',
        'size_type',
        'size_dollars'
      ].forEach((field) => setValue(field, entity[field]))

      setValue('is_utility_company', entity.is_utility_company ? 'Yes' : 'No')

      entity.notices_address && [
        'line1',
        'city',
        'country',
        'region',
        'postal_code',
      ].forEach((field) => setValue(`notices_${field}`, entity.notices_address[field]))

      entity.billing_address && [
        'line1',
        'city',
        'country',
        'region',
        'postal_code',
      ].forEach((field) => setValue(`billing_${field}`, entity.billing_address[field]))
    }
  }, [entity])

  useEffect(loadCreditRatingsAgencies, [])


  const formatPayload = (formData) => {
    const {
      agency_credit_rating_id,
      agency_credit_rating_date,
      form_of_organization,
      jurisdiction_of_organization,
      jurisdiction_of_organization_identifier,
      is_utility_company,
      size_type,
      size_dollars,
      sanctions_flag,
      sanctions_details,
      notices_line1,
      notices_line2,
      notices_city,
      notices_region,
      notices_postal_code,
      notices_country,
      billing_line1,
      billing_line2,
      billing_city,
      billing_region,
      billing_postal_code,
      billing_country
    } = formData

    return {
      agency_credit_rating_id,
      agency_credit_rating_date,
      form_of_organization,
      jurisdiction_of_organization,
      jurisdiction_of_organization_identifier,
      is_utility_company: 'Yes' === is_utility_company,
      size_type,
      size_dollars,
      sanctions_flag,
      sanctions_details,
      notices_address: {
        line1: notices_line1,
        line2: notices_line2,
        city: notices_city,
        region: notices_region,
        postal_code: notices_postal_code,
        country: notices_country
      },
      billing_address: {
        line1: billing_line1,
        line2: billing_line2,
        city: billing_city,
        region: billing_region,
        postal_code: billing_postal_code,
        country: billing_country
      }
    }
  }

  const saveAction = async () => {
    await updateEntity(user.entity_id, formatPayload(getValues()))
    toast.success(`Updated ${entity.name} information`)
  }

  const signAction = async () => {
    const signatureUrlResponse = await getSignatureUrl(entity.id)
    setEntitySignatureModalOpen({ ...entity, signatureUrlResponse })
  }

  const onboardAction = async (data) => {
    await updateEntity(user.entity_id, formatPayload(data))
    await signAction()
  }

  const noticesCountry = useRef({})
  noticesCountry.current = watch('notices_country', '')

  const billingCountry = useRef({})
  billingCountry.current = watch('billing_country', '')

  const entityCreditRating = useMemo(() => creditRatingsAgencies?.find(({ id }) => id === entity?.agency_credit_rating_id), [creditRatingsAgencies, entity?.agency_credit_rating_id])

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-2xl">
        <Logo LogoImage={LogoImage} alt="Tellus Iam logo"/>
        {entity && Object.keys(creditRatingsAgenciesGrouped).length && (
          <form onSubmit={handleSubmit(onboardAction)}>
            <h2 className="mt-6 text-center text-xl font-extrabold text-gray-900">{entity.name} Onboarding</h2>
            {entity?.status === 'review' && (
              <div className="mt-6">
                <Alert id="onboarding_complete_alert" type="success"
                  message="Onboarding completed! Look out for an email with the status of application review"/>
              </div>
            )}

            <div className="mt-8">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-4">

                {/* region Address for notices */}
                <h3 className="text-lg leading-6 font-medium text-gray-900 pb-4 border-b border-gray-300">
                  Organization address for notices
                </h3>
                <div className="grid sm:gap-x-8 gap-y-4 grid-cols-1 sm:grid-cols-12 pt-4">
                  <div className="sm:col-span-6">
                    <label htmlFor="notices_line1" className="pb-2 block text-sm text-gray-700">
                      Address
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        id="notices_line1"
                        name="notices_line1"
                        register={register('notices_line1', { required: 'Required', })}
                        errors={errors}
                        autoComplete="address-line1"
                      />
                      : <span>{entity.notices_address.line1}</span>
                    }

                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="notices_city" className="pb-2 block text-sm text-gray-700">
                      City
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        id="notices_city"
                        name="notices_city"
                        register={register('notices_city', { required: 'Required', })}
                        errors={errors}
                      />
                      : <span>{entity.notices_address.city}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="notices_country" className="pb-2 block text-sm text-gray-700">
                      Country
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="notices_country"
                        name="notices_country"
                        options={Object.keys(countries)}
                        register={register('notices_country', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entity.notices_address.country}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="notices_region" className="pb-2 block text-sm text-gray-700">
                      State / Province
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="notices_region"
                        name="notices_region"
                        disabled={!noticesCountry.current}
                        options={countries[noticesCountry.current] || []}
                        register={register('notices_region', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entity.notices_address.region}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="notices_postal_code" className="pb-2 block text-sm text-gray-700">
                      Postal code
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        id="notices_postal_code"
                        name="notices_postal_code"
                        register={register('notices_postal_code', { required: 'Required', })}
                        errors={errors}
                        autoComplete="postal-code"
                      />
                      : <span>{entity.notices_address.postal_code}</span>
                    }
                  </div>

                </div>
                {/* endregion */}

                {/* region Billing address */}
                <h3 className="text-lg leading-6 font-medium text-gray-900 pb-4 border-b border-gray-300">
                  Organization billing address
                </h3>
                <div className="grid sm:gap-x-8 gap-y-4 grid-cols-1 sm:grid-cols-12 pt-4">
                  <div className="sm:col-span-6">
                    <label htmlFor="billing_line1" className="pb-2 block text-sm text-gray-700">
                      Address
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        id="billing_line1"
                        name="billing_line1"
                        register={register('billing_line1', { required: 'Required', })}
                        errors={errors}
                        autoComplete="address-line1"
                      />
                      : <span>{entity.billing_address.line1}</span>
                    }

                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="billing_city" className="pb-2 block text-sm text-gray-700">
                      City
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        id="billing_city"
                        name="billing_city"
                        register={register('billing_city', { required: 'Required', })}
                        errors={errors}
                      />
                      : <span>{entity.billing_address.city}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="billing_country" className="pb-2 block text-sm text-gray-700">
                      Country
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="billing_country"
                        name="billing_country"
                        options={Object.keys(countries)}
                        register={register('billing_country', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entity.billing_address.country}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="billing_region" className="pb-2 block text-sm text-gray-700">
                      State / Province
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="billing_region"
                        name="billing_region"
                        disabled={!billingCountry.current}
                        options={countries[billingCountry.current] || []}
                        register={register('billing_region', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entity.billing_address.region}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="billing_postal_code" className="pb-2 block text-sm text-gray-700">
                      Postal code
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        id="billing_postal_code"
                        name="billing_postal_code"
                        register={register('billing_postal_code', { required: 'Required', })}
                        errors={errors}
                        autoComplete="postal-code"
                      />
                      : <span>{entity.billing_address.postal_code}</span>
                    }
                  </div>

                </div>
                {/* endregion */}

                {/* region Entity details */}
                <h3 className="text-lg leading-6 font-medium text-gray-900 pb-4 border-b border-gray-300">
                  Organization details
                </h3>

                <div className="grid sm:gap-x-8 gap-y-4 grid-cols-1 sm:grid-cols-12 pt-4">
                  <div className="sm:col-span-4">
                    <label htmlFor="form_of_organization" className="pb-2 block text-sm text-gray-700">
                      Form of organization
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="form_of_organization"
                        name="form_of_organization"
                        options={formsOfOrganization}
                        register={register('form_of_organization', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entity.form_of_organization}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="jurisdiction_of_organization" className="pb-2 block text-sm text-gray-700">
                      Jurisdiction
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="jurisdiction_of_organization"
                        name="jurisdiction_of_organization"
                        options={usStates}
                        register={register('jurisdiction_of_organization', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entity.jurisdiction_of_organization}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="jurisdiction_of_organization_identifier"
                      className="pb-2 block text-sm text-gray-700">
                      Jurisdiction ID
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        id="jurisdiction_of_organization_identifier"
                        name="jurisdiction_of_organization_identifier"
                        register={register('jurisdiction_of_organization_identifier', { required: 'Required', })}
                        errors={errors}
                        autoComplete="jurisdiction_of_organization_identifier"
                      />
                      : <span>{entity.jurisdiction_of_organization_identifier}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="is_utility_company" className="pb-2 block text-sm text-gray-700">
                      Is utility company?
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="is_utility_company"
                        name="is_utility_company"
                        options={utilityCompanyTypes}
                        register={register('is_utility_company', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entity.is_utility_company}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="size_type" className="pb-2 block text-sm text-gray-700">
                      Organization size of
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="size_type"
                        name="size_type"
                        options={organizationSizeTypes}
                        register={register('size_type', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entity.size_type}</span>
                    }
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="size_dollars" className="pb-2 block text-sm text-gray-700">
                      Organization size
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        type="number"
                        id="size_dollars"
                        name="size_dollars"
                        register={register('size_dollars', { required: 'Required', })}
                        errors={errors}
                        postfix="$"
                        className="pr-20"
                      />
                      : <span>{dollarsToScientific(entity.size_dollars)}</span>
                    }
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="agency_credit_rating_id" className="pb-2 block text-sm text-gray-700">
                      Credit rating
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Select
                        id="agency_credit_rating_id"
                        name="agency_credit_rating_id"
                        groupedOptions={creditRatingsAgenciesGrouped}
                        register={register('agency_credit_rating_id', { required: 'Required' })}
                        errors={errors}
                        placeholder=""
                        className="mr-4"
                      />
                      : <span>{entityCreditRating.agency} {entityCreditRating.rating}</span>
                    }
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="agency_credit_rating_date" className="pb-2 block text-sm text-gray-700">
                      Credit rating date
                    </label>
                    {entity.status === 'draft'
                      ?
                      <Input
                        id="agency_credit_rating_date"
                        type="date"
                        name="agency_credit_rating_date"
                        register={register('agency_credit_rating_date', { required: 'Required', })}
                        errors={errors}
                      />
                      : <span>{entity.agency_credit_rating_date}</span>
                    }
                  </div>
                </div>
                {/* endregion */}

              </div>

              <div className="float-right text-center space-y-4 my-4">
                {entity.status === 'draft' && (
                  <>
                    <Button
                      id="save_progress"
                      secondary
                      className="mx-4"
                      onClick={() => saveAction()}
                      disabled={!isDirty}
                    >
                      Save progress
                    </Button>
                    <Button id="submit" type="submit">Submit for onboarding</Button>
                  </>
                )}

                {entity.status === 'pending_agreement' && (
                  <Button onClick={() => signAction()}>Sign the agreement</Button>
                )}
              </div>

            </div>
          </form>
        )}
      </div>
    </div>
  )
}
