// Default Rails imports
import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'

// React
import ReactDOM from 'react-dom'
import React from 'react'
import App from '../App'

console.log('Vite ⚡️ Rails')

// Boot built-in rails components
Rails.start()
ActiveStorage.start()

// Boot React app!
document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <App/>,
    document.body.appendChild(document.createElement('div')),
  )
})

