import React from 'react'

import Navbar from './Navbar'
import { Route, Switch } from 'react-router-dom'
import { Home, NotFound404 } from '../../views'
import { PrivateRoute } from '../../components/PrivateRoute'


export default function StackedLayout() {
  return (
    <div className="min-h-screen">
      <Navbar/>
      <Switch>
        <PrivateRoute path="/app/home" component={Home}/>
        <Route path={['/app/404', '*']}>
          <NotFound404/>
        </Route>
      </Switch>
    </div>
  )
}
