export const capitalize = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const centsToDollars = (cents) => parseFloat((Math.round(cents * 100) / 10000))

export const dollarsToCents = (dollars) => Math.round(dollars * 100)

export const dateToYearMonth = (date) => date && date.split('-').map(d => parseInt(d))

export const yearMonthToDate = (year, month) => `${year}-${String(month).padStart(2, '0')}-01`

export const dateToYYYYMMDD = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

export const YYYYMMDDToDate = (date) => {
  if (!date) return new Date()
  const [year, month, day] = date.split('-')
  return new Date(year, month - 1, day)
}

export const dollarsToScientific = (dollars, precision = 2, prependDollarSign = true) => {
  const postfixes = ['K', 'M', 'B', 'T']
  if (dollars < 1000) {
    return `${prependDollarSign ? '$' : ''}${dollars}`
  }
  if (dollars > 10 ** 15) {
    return 'Extraordinary big'
  }

  let index = 1
  while (dollars > (10 ** ((index + 1) * 3))) {
    index++
  }

  const actualValue = dollars / 10 ** ((index) * 3)
  const rounded = Math.round(actualValue * 10 ** precision) / 10 ** precision
  return `${prependDollarSign ? '$' : ''}${rounded}${postfixes[index - 1]}`
}

export const addToDate = (date, years, months = 0, days = 0) =>
  new Date(date.getFullYear() + years, date.getMonth() + months, date.getDate() + days)

export const asDict = (items, mapper) => {
  return Object.assign({}, ...items.map((x) => ({ [x.id]: mapper(x) })))
}

export const shuffleArray = array => array.map(value => ({ value, sort: Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value)

export const floatToFixed = (number, decimals = 2) => Number.parseFloat(number).toFixed(decimals)
