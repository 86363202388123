import React from 'react'

import Spinner from '../Spinner'

export const buttonTheme = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  negative: 'negative',
  round: 'round',
}

const Button = (props) => {
  let {
    primary,
    secondary,
    tertiary,
    negative,
    round,
    circular,
    className = '',
    type = 'button',
    size = 1,
    children,
    disabled,
    loading,
    ...otherProps
  } = props
  disabled = loading ? true : disabled
  const currentButtonTheme = Object.keys(buttonTheme).reduce((a, theme) => {
    if (a) return a
    return (theme in props) ? theme : a
  }, '') || 'primary'

  // todo: fix disabled:opacity-50
  const commonClasses = `relative border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2${disabled ? ' opacity-25 cursor-not-allowed' : ''}`
  const themeClasses = {
    [buttonTheme.primary]: `border-transparent bg-bonfire-dark shadow-sm text-white focus:border-ring-bonfire-dark-light ${!disabled ? 'hover:bg-bonfire-dark-light' : ''}`,
    [buttonTheme.secondary]: `border-transparent text-white bg-perfect-shine focus:ring-perfect-shine-dark ${!disabled ? 'hover:bg-perfect-shine-dark' : ''}`,
    [buttonTheme.negative]: `border-cerise-red focus:cerise-red-light focus:border-cerise-red-light ${!disabled ? 'hover:bg-red-50 text-cerise-red-dark' : ''}`,
    [buttonTheme.tertiary]: `border-gray-300 shadow-sm text-gray-700 bg-white focus:border-ring-bonfire-dark-light ${!disabled ? 'hover:bg-gray-50' : ''}`,
    [buttonTheme.round]: `rounded-full border-transparent bg-bonfire-dark shadow-sm text-white focus:border-ring-bonfire-dark-light ${!disabled ? 'hover:bg-bonfire-dark-light' : ''}`,
  }
  const sizeClasses = [
    'px-2.5 py-1.5 text-xs rounded',
    'px-3 py-2 text-xs sm:text-sm leading-4 rounded-md',
    'px-4 py-2 text-xs sm:text-sm rounded-md',
    'px-4 py-2 text-base rounded-md',
    'px-6 py-3 text-base rounded-md',
  ]

  return (
    <button
      {...otherProps}
      disabled={disabled}
      type={type}
      className={`${className} ${commonClasses} ${themeClasses[currentButtonTheme]} ${sizeClasses[size]}`}
    >
      <div className="inline-flex items-center" style={{ visibility: loading ? 'hidden' : 'visible' }}>
        {children}
      </div>
      {loading && (
        <div className="absolute" style={{ left: 'calc(50% - 12px)', top: 'calc(50% - 12px)', }}>
          <Spinner size="6" my="0"/>
        </div>
      )}
    </button>
  )
}

export default Button
