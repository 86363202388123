import { useUserService } from '@climate/components'
import { Redirect, Route } from 'react-router-dom'
import React from 'react'

export function PrivateRoute({ component: Component, role, ...rest }) {
  const { user, isUserInRole } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')
  return (
    <Route
      {...rest}
      render={({ location, props }) => {
        if (user.token) {
          return isUserInRole(role)
            ? <Component {...props} {...rest} />
            : <Redirect to={{ pathname: '/app/home', state: { from: location } }}/>
        } else {
          return <Redirect to={{ pathname: '/app/login', state: { from: location } }}/>
        }
      }}
    />
  )
}
