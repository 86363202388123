import React from 'react'
import { BarStack } from '@visx/shape'
import { Group } from '@visx/group'
import { AxisBottom } from '@visx/axis'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip'
import { LegendOrdinal } from '@visx/legend'
import { localPoint } from '@visx/event'
import { ParentSize } from '@visx/responsive'

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 }
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
  lineHeight: '1rem',
}

let tooltipTimeout

const BarStackChart = ({
  data,
  xKey,
  width,
  height,
  margin = defaultMargin,
}) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip()

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  })

  const keys = Object.keys(data[0]).filter((d) => d !== xKey)

  const valueTotals = data.reduce((allTotals, xValues) => {
    const totalValue = keys.reduce((localTotal, k) => {
      localTotal += Number(xValues[k])
      return localTotal
    }, 0)
    allTotals.push(totalValue)
    return allTotals
  }, [])

  const getX = (d) => d[xKey]

  const xScale = scaleBand({
    domain: data.map(getX),
    padding: 0.2,
  })
  const yScale = scaleLinear({
    domain: [0, Math.max(...valueTotals)],
    nice: true,
  })
  const colorScale = scaleOrdinal({
    domain: keys,
    range: ['#40916c', '#FFB500', '#0077b6', '#0089A3'],
  })

  if (width < 10) return null

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  xScale.rangeRound([0, xMax])
  yScale.range([yMax, 0])

  return width < 10 ? null : (
    <div style={{ position: 'relative', left: `${margin.left}px`, marginBottom: `${margin.bottom}px` }}>
      <svg ref={containerRef} width={width} height={height}>
        <Group top={margin.top}>
          <BarStack
            data={data}
            keys={keys}
            height={yMax}
            x={getX}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x + bar.width * .25}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width * .50}
                    fill={bar.color}
                    onMouseLeave={() => {
                      tooltipTimeout = window.setTimeout(() => {
                        hideTooltip()
                      }, 300)
                    }}
                    onMouseMove={(event) => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout)
                      const eventSvgCoords = localPoint(event)
                      const left = bar.x + bar.width / 2
                      showTooltip({
                        tooltipData: bar,
                        tooltipTop: eventSvgCoords.y,
                        tooltipLeft: left,
                      })
                    }}
                  />
                )),
              )
            }
          </BarStack>
        </Group>
        <AxisBottom
          top={yMax + margin.top}
          scale={xScale}
          stroke={'#888'}
          tickStroke={'#666'}
          tickLabelProps={() => ({
            fill: '#333',
            fontSize: 11,
            textAnchor: 'middle',
          })}
        />
      </svg>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '14px',
        }}
      >
        <LegendOrdinal scale={colorScale} direction="row" labelMargin="0 15px 0 0"/>
      </div>

      {
        tooltipOpen && tooltipData && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="space-y-2">
              <div style={{ color: colorScale(tooltipData.key) }}>
                <strong>{tooltipData.key}</strong>
              </div>
              <div>{tooltipData.bar.data[tooltipData.key]} tons</div>
              <div>
                <small>{getX(tooltipData.bar.data)}</small>
              </div>
            </div>
          </TooltipInPortal>
        )
      }
    </div>
  )
}

const BarStackChartContainer = ({ data, minHeight = 400, xKey, margin }) => (
  <ParentSize style={{ minHeight }}>
    {(parent) =>
      <BarStackChart
        data={data}
        xKey={xKey}
        width={parent.width}
        height={parent.height}
        margin={margin}
      />}
  </ParentSize>
)

export default BarStackChartContainer
