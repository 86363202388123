import React, { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Logo, useUserService } from '@climate/components'
import Avatar from 'react-avatar'

import LogoImage from '../../../assets/images/logo.svg'

function Navbar() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const { user, logout } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')

  const userNavigation = [
    {
      name: 'Contact support', onClick: (e) => {
        window.location = 'mailto:support@tellusmarkets.com'
        e.preventDefault()
      }
    },
    { name: 'Sign out', onClick: logout }
  ].filter(Boolean)

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="bg-white border">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Logo LogoImage={LogoImage} alt="Tellus Iam logo"/>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  {({ open }) => (
                    <>
                      <div data-cy="profile-button">
                        <Menu.Button
                          className="bg-white flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-atlantic-mood-light">
                          <Avatar color="transparent" className="bg-pacific-storm" name={user.full_name}
                            textSizeRatio={2} size={40} round={true}/>
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="z-50 origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <Menu.Item as="span"
                            className="truncate cursor-default text-white rounded-t-md bg-pacific-storm block px-4 pt-2 text-xs">
                            {user.full_name}
                          </Menu.Item>
                          <Menu.Item as="span"
                            className="truncate cursor-default text-white bg-pacific-storm block px-4 pb-2 text-xs">
                            {user.entity_name}
                          </Menu.Item>
                          {userNavigation.map((item) => (
                            <Menu.Item
                              key={item.name}>
                              {({ active }) => (
                                <a
                                  data-cy={`${item.name.replace(/\s+/g, '-').toLowerCase()}-menu-item`}
                                  href={item.href}
                                  onClick={item.onClick}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fine-barrel-light">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true"/>
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <div className="hidden sm:flex h-16">
            {/* subnav height holder */}
          </div>


          <Disclosure.Panel className="sm:hidden">
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <Avatar color="transparent" className="bg-pacific-storm focus:ring-atlantic-moo-light"
                    name={user.full_name} textSizeRatio={2} size={40} round={true}/>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">{user.name}</div>
                  <div className="text-sm font-medium text-gray-500">{user.email}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    onClick={item.onClick}
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 cursor-pointer"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Navbar
