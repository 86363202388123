import { useEffect, useState } from 'react'
import { useEntitiesService } from '../services'
import { useUserService } from '@climate/components'
import toast from 'react-hot-toast'
import HelloSign from 'hellosign-embedded'

// todo: refactor

export const useEntitySignatureModal = (onClose) => {
  const { token } = useUserService(window.config.iam_service_url, window.config.session_cookie, 'iam')
  const { recordSignature } = useEntitiesService(token)
  const [entity, setEntity] = useState(null)
  const [open, setOpen] = useState(null)

  useEffect(() => {
    (async () => {
      if (entity && !open) {
        const { signatureUrlResponse } = entity
        if (signatureUrlResponse.signed_flag) {
          await recordSignature(entity.id)
          toast.success('The document has already been signed')
          onClose && onClose(entity)
          setEntity(null)
          setOpen(null)
        } else { // Open HelloSign dialog!
          const url = signatureUrlResponse.signature_url
          const client = new HelloSign({
            clientId: signatureUrlResponse.hellosign_client_id,
            testMode: true,
            skipDomainVerification: true,
            allowCancel: true
          })

          client.on('close', () => {
            client.close()
            onClose && onClose(entity)
            setEntity(null)
            setOpen(null)
          })

          client.on('sign', async () => {
            await recordSignature(entity.id)
            toast.success('The document has been signed')
            onClose && onClose(entity)
            setEntity(null)
            setOpen(null)
          })

          client.open(url)

          return () => {
            setEntity(null)
            setOpen(null)
            client.close()
          }
        }
      }
    })()
  })

  return {
    setEntitySignatureModalOpen: (entity) => setEntity(entity)
  }
}
