import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound404() {
  return (
    <div className="flex flex-col" style={{ minHeight: 'calc(100vh - 128px)' }}>
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-16">
          <div className="text-center">
            <h3 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">Page not found</h3>
            <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for</p>
            <div className="mt-6">
              <Link
                to="/app/home"
                className="text-base font-medium text-blue-600 hover:text-blue-500"
              >
                Go to dashboard<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
