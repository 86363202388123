import { atom, useRecoilState } from 'recoil'
import { http, useAsyncError } from '@climate/components'

const ENDPOINT = '/api/v1/entities'

const entityAtom = atom({
  key: 'entity',
  default: undefined,
})

export function useEntitiesService(token) {
  const throwError = useAsyncError()

  const [entity, setEntity] = useRecoilState(entityAtom)

  const getEntity = async (id) => {
    try {
      const entity = (await http.get({ url: `${ENDPOINT}/${id}`, token }))
      setEntity(entity)
      return entity
    } catch (e) {
      throwError(e)
    }
  }

  const updateEntity = async (id, payload) => {
    try {
      const entity = (await http.put({ url: `${ENDPOINT}/${id}`, payload, token }))
      setEntity(entity)
      return entity
    } catch (e) {
      throwError(e)
    }
  }

  const getSignatureUrl = async (id) => {
    try {
      return (await http.post({ url: `${ENDPOINT}/${id}/signature_url`, payload: {}, token }))
    } catch (e) {
      throwError(e)
    }
  }

  const recordSignature = async (id) => {
    try {
      return (await http.post({ url: `${ENDPOINT}/${id}/record_signature`, payload: {}, token }))
    } catch (e) {
      throwError(e)
    }
  }

  return {
    entity,
    getEntity,
    updateEntity,
    getSignatureUrl,
    recordSignature,
  }
}
