export class HttpError extends Error {
  // todo: discuss / refactor
  constructor(status, data, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError)
    }

    this.name = 'HttpError'
    this.status = status
    this.data = data
  }
}
