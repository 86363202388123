import React from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { Button } from './ui'

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex items-center lg:px-8 max-w-7xl min-h-screen mx-auto px-4 sm:px-6 w-screen">
        <div className="max-w-3xl mx-auto w-screen">
          <div className="rounded-md bg-red-50 p-4 border">
            <div className="flex">
              <div className="ml-3">
                {error.status == 401 && (<>
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                  </div>
                  <h3 className="text-sm font-medium text-red-800">You session has expired</h3>
                  <div className="space-x-4 mt-4">
                    <Button onClick={resetErrorBoundary}>Login</Button>
                  </div>
                </>)}

                {error.status != 401 && (<>
                  <div className="flex flex-shrink-0 space-x-4">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true"/>
                    <h3 className="text-sm font-medium text-red-800">Something went wrong</h3>
                  </div>
                  <div className="mt-2 text-sm text-red-700">
                    {error.message}
                  </div>
                  <div className="space-x-4 mt-4">
                    <Button onClick={resetErrorBoundary}>Back</Button>
                  </div>
                </>)}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export function ErrorBoundary({ children }) {
  const history = useHistory()
  const [error, setError] = React.useState(null)

  const onError = async (error) => {
    setError(error)
    console.error(error)
  }

  const onReset = () => {
    if (error.status == 401) {
      history.push('/app/login')
    } else {
      history.push('/app/home')
    }
  }

  return (
    <ReactErrorBoundary onError={onError} FallbackComponent={ErrorFallback} onReset={onReset}>
      {children}
    </ReactErrorBoundary>
  )
}
